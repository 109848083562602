import React from 'react';

export default function OptionCard({ onClick, option, image, color, text, fontStyle = {} }) {
  return (
    <button className="w-[200px]" onClick={() => onClick()}>
      <div
        style={{ backgroundColor: color, borderWidth: '3px', borderColor: color }}
        className="rounded-lg transform transition duration-200 hover:scale-105"
      >
        <img className="rounded-t-lg h-[150px] w-full" src={image} key={text} alt={text} />
        <div
          style={{ borderColor: color, fontSize: '1.5rem', ...fontStyle }}
          className="w-full bg-white py-6 px-3 border-t-2 rounded-b-lg text-2xl text-center text-black focus:outline-none"
        >
          {text}
        </div>
      </div>
    </button>
  );
}
