import React from 'react';

// ISSUE: https://github.com/facebook/react/issues/5030

export default function Background({ image }) {
  return (
    <div
      id="Background"
      style={{
        position: 'fixed',
        zIndex: 0,
        height: '100%',
        width: '100%',
        // NOTE: DO NOT USE 'background:' - causes errors with react re-rendering
        //background: `url(${image})`,
        backgroundImage: `url(${image})`,
        backgroundAttachment: 'fixed',
        backgroundPositionX: 'right',
        backgroundPositionY: 'bottom',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        overflowY: 'hidden',
      }}
    />
  );
}
