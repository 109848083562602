import React from 'react';
import { useCss } from 'react-use';

import { Logo, Title, SpeechBubble } from '../../sharedComponents/';
import { Header, Content, ContentFooter, Footer } from '../../LayoutComponents/';
import OptionCircle from './OptionCircle';

const OPTIONS = [
  {
    image: 'https://imgur.com/AYf6A0e.png',
    color: '#9D7751',
    text: 'Classic',
  },
  {
    image: 'https://imgur.com/tmGpSTF.png',
    color: '#FB86A0',
    text: 'Pink',
  },
  {
    image: 'https://imgur.com/8SgJDz6.png',
    color: '#FFCC01',
    text: 'Gold',
  },
  {
    image: 'https://imgur.com/iuJakAc.png',
    color: '#B8B8B8',
    text: 'Diamond',
  },
  {
    image: 'https://imgur.com/2vVDDDu.png',
    color: '#FF3B2F',
    text: 'Mahogany',
  },
  {
    image: 'https://imgur.com/NEiVtjI.png',
    color: '#8E8E93',
    text: 'Robot',
  },
  {
    image: 'https://imgur.com/WscR5R4.png',
    color: '#4BD964',
    text: 'Ocean Green',
  },
  {
    image: 'https://imgur.com/wUYkr9c.png',
    color: '#4BD964',
    text: 'Royal',
  },
];

export default function Step03({ addTextToUsersSelections, goToNextStep }) {
  const titleClassName = useCss({
    textAlign: 'center',
    color: '#fff',
    fontSize: '60px',
    lineHeight: '68px',
    '@media only screen and (max-width: 900px)': {
      fontSize: '36px',
      lineHeight: '40px',
    },
  });

  const onOptionSelect = selectedText => {
    addTextToUsersSelections(selectedText);
    goToNextStep();
  };

  return (
    <>
      <Header className="mb-20">
        <div
          className="bg-black bg-opacity-60"
          style={{
            margin: 'auto',
            width: '100px',
            border: '2px solid rgba(0,0,0, 0.6)',
            borderTop: 'none',
            padding: '10px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            borderRadius: '0 0 1rem 1rem',
          }}
        >
          <Logo />
        </div>
        <div className="my-10">
          <Title>
            Nice choice!
            <br />
            What should your ship be made of?
          </Title>
        </div>
      </Header>

      <Content>
        <div className="flex p-5" style={{ justifyContent: 'center' }}>
          <div style={{ width: '100%', maxWidth: '1000px' }}>
            <div
              id="centeredCardsContainer"
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignContent: 'flex-center',
                gap: '30px',
              }}
            >
              {OPTIONS.map(option => (
                <OptionCircle
                  image={option.image}
                  color={option.color}
                  text={option.text}
                  onClick={() => onOptionSelect(option.text)}
                />
              ))}
            </div>
          </div>
        </div>
      </Content>

      <Footer className="flex" style={{ justifyContent: 'center' }}></Footer>
    </>
  );
}
