import React from 'react';

// http://projects.verou.me/bubbly/

import './styles.css';

export default function SpeechBubble({ children, style, bubblePointerLeft, bubblePointerBottom }) {
  const styles = {
    ...style,
    border: '2px solid rgba(255,217,109, 0.447205)',
    backgroundColor: 'rgba(0,0,0, 0.6)',
  };

  if (bubblePointerLeft) {
    return (
      <div className="speech-bubble speech-bubble-left" style={{ ...styles }}>
        {children}
      </div>
    );
  }

  if (bubblePointerBottom) {
    return (
      <div className="speech-bubble speech-bubble-bottom" style={{ ...styles }}>
        {children}
      </div>
    );
  }

  return (
    <div className="speech-bubble speech-bubble-right" style={{ ...styles }}>
      {children}
    </div>
  );
}
