import React from 'react';

export default function PoweredByMemberrTag() {
  return (
    <a href="https://memberr.xyz" target="_blank" rel="noreferrer">
      <span className="relative inline-block px-5 py-2 text-white leading-tight">
        <span aria-hidden className="absolute inset-0 bg-black rounded-xl"></span>
        <span className="relative">
          powered by <b>memberr</b>
        </span>
      </span>
    </a>
  );
}
