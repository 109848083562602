import React from 'react';

import { Logo, Title } from '../../sharedComponents/';
import { Header, Content, ContentFooter, Footer } from '../../LayoutComponents/';
import OptionCard from './OptionCard';

const OPTIONS = [
  {
    image: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/6cdea19c-1550-4802-2bd2-3463beb52d00/public',
    color: '#5856D5',
    text: 'Apelantic',
  },
  {
    image: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/d119655b-e5f2-4d60-2281-3f7165263e00/public',
    color: '#FFCC01',
    text: 'Apebiza',
  },
  {
    image: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/fc2b9b0b-cfe8-4895-fb1e-7184338aaa00/public',
    color: '#239636',
    text: 'Everglades Ape Park',
    fontStyle: {
      fontSize: '1.1rem',
      lineHeight: '1.3rem',
    },
  },
  {
    image: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/c51892dd-7ae4-47c4-3a02-afbe69df0c00/public',
    color: '#007AFF',
    text: 'Banana Bay',
  },
  {
    image: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/4d9c2c5a-7d6b-4976-d156-032243f41f00/public',
    color: '#FF3B2F',
    text: 'Ape Harbour',
  },
  {
    image: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/2bb88ecc-27f1-4746-4669-b36d53b6af00/public',
    color: '#5AC8FA',
    text: 'Ape Island',
  },
];

export default function Step02({ addTextToUsersSelections, goToNextStep }) {
  const onOptionSelect = selectedText => {
    addTextToUsersSelections(selectedText);
    goToNextStep();
  };

  return (
    <>
      <Header>
        <div
          className="bg-black bg-opacity-60"
          style={{
            margin: 'auto',
            width: '100px',
            border: '2px solid rgba(0,0,0, 0.6)',
            borderTop: 'none',
            padding: '10px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            borderRadius: '0 0 1rem 1rem',
          }}
        >
          <Logo />
        </div>
        <div className="my-10">
          <Title>Where would you like to go?</Title>
        </div>
      </Header>

      <Content>
        <div
          id="centeredCardsContainer"
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'flex-center',
            gap: '10px',
          }}
        >
          {OPTIONS.map(({ image, color, text, fontStyle }) => (
            <OptionCard
              image={image}
              color={color}
              text={text}
              fontStyle={fontStyle}
              onClick={() => onOptionSelect(text)}
            />
          ))}
        </div>
      </Content>

      <Footer className="flex" style={{ justifyContent: 'center' }}>
        {/*
        <div className="flex" style={{ maxWidth: '1050px', justifyContent: 'center' }}>
          <img
            src="/images/BAYC_Ape_Grills.png"
            alt="BAYC_Ape_Grills"
            style={{
              alignSelf: 'flex-end',
              width: '40%',
              height: 'auto',
              marginLeft: '5%',
            }}
          />
          <SpeechBubble
            bubblePointerLeft={true}
            style={{
              alignSelf: 'flex-start',
              marginLeft: '15%',
              marginRight: '5%',
              marginBottom: '5%',
              marginTop: '5%',
            }}
          >
            <div className="p-5 text-left text-white text-xl md:text-2xl">
              <p>Yo, I’m Mel. We built this harbour in 2021 for Ape Holders and ambitious adventurers.</p>
            </div>
          </SpeechBubble>
        </div>
        */}
      </Footer>
    </>
  );
}
