import React from 'react';
import { useWindowSize, useCss } from 'react-use';

import { Logo, Title, SpeechBubble } from '../../sharedComponents/';
import { Header, Content, ContentFooter, Footer } from '../../LayoutComponents/';

export default function Step01({ goToNextStep }) {
  const apeClassName = useCss({
    alignSelf: 'flex-end',
    width: 'auto',
    height: '40%',
    marginRight: '20%',
    cursor: 'pointer',
    '@media only screen and (max-width: 900px)': {
      height: '30%',
      marginRight: 0,
      maxHeight: '350px',
    },
  });

  const { width: windowWidth } = useWindowSize();

  const onMobile = windowWidth <= 700;

  const speechBubbleClassName = useCss({
    '@media only screen and (max-width: 900px)': {
      fontSize: '19px',
    },
  });

  return (
    <>
      <Header className="mb-20">
        <div
          className="bg-black bg-opacity-60"
          style={{
            margin: 'auto',
            width: '100px',
            border: '2px solid rgba(0,0,0, 0.6)',
            borderTop: 'none',
            padding: '10px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            borderRadius: '0 0 1rem 1rem',
          }}
        >
          <Logo />
        </div>

        <div className="mt-10 items-center" style={{ padding: '0 10%', lineHeight: '62px' }}>
          <Title>
            {onMobile ? (
              'Welcome to Ape Harbour'
            ) : (
              <>
                Welcome to Ape Harbour! <br />
                The #1 yachting community in the metaverse
              </>
            )}
          </Title>
        </div>
      </Header>

      <Content>
        <SpeechBubble
          bubblePointerBottom={true}
          className={speechBubbleClassName}
          style={{
            alignSelf: 'flex-start',
            marginTop: '3%',
            marginLeft: '15%',
            marginRight: '5%',
            maxWidth: '700px',
          }}
        >
          <div className={`p-10 text-left text-white text-xl md:text-2xl ${speechBubbleClassName}`}>
            <p>
              Ready to get your first ship and conquer the meta-ocean?
              <br />
              Let's find your ship!{' '}
              <span className="mt-5" style={{ color: '#FF8630', cursor: 'pointer' }} onClick={() => goToNextStep()}>
                Click here to start
              </span>
            </p>
          </div>
        </SpeechBubble>
      </Content>

      <ContentFooter></ContentFooter>

      <Footer className="flex" style={{ justifyContent: 'right' }}>
        <img
          //src="/images/BAYC_Ape_Gold_mirrored.png"
          src="/images/BAYC_Ape_Gold_outliend.png"
          alt="BAYC_Ape_Gold"
          className={apeClassName}
          onClick={() => goToNextStep()}
        />
      </Footer>
    </>
  );
}
