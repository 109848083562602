import React from 'react';

export default function Logo() {
  return (
    <img
      src="https://imgur.com/CIxZPLX.png"
      alt="Ape_Harbour_Yachts_Logo"
      className="h-[70px] w-[70px]"
      style={{
        whiteSpace: 'normal',
        display: 'inline-block',
        verticalAlign: 'middle',
      }}
    />
  );
}
