import React, { useState, useEffect } from 'react';

import Background from './Background';
import { PoweredByMemberrTag } from './sharedComponents';

import { Step01, Step02, Step03, Step04, Step05, StepDetailsForm, StepSuccess } from './funnelSteps';

export default function Funnel({
  tx,
  externalContracts,
  writeContracts,
  gasPrice,
  address,
  userProvider,
  localProvider,

  walletIsConnected,
  walletOwnershipIsVerified,
  setWalletOwnershipIsVerified,
  accountView,
}) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [usersSelections, setUsersSelections] = useState([]);
  const [usersTwitterHandle, setUsersTwitterHandle] = useState('');
  const [userWalletDetails, setUserWalletDetails] = useState({
    address: null,
    privateKey: null,
    walletFile: null,
    password: null,
  });

  const addTextToUsersSelections = newSelectionString => {
    const _usersSelections = [...usersSelections, newSelectionString];
    setUsersSelections(_usersSelections);
  };

  console.log({ usersSelections });
  console.log({ currentStepIndex });
  console.log({ externalContracts });

  const goToNextStep = () => {
    setCurrentStepIndex(currentStepIndex + 1);
  };

  useEffect(() => {
    // scroll to top on each step change
    const scrollContainer = document.getElementById('scrollContainer');
    scrollContainer.scrollTop = 0;
  }, [currentStepIndex]);

  const stepsList = [
    {
      view: <Step01 goToNextStep={goToNextStep} />,
      backgroundUrl: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/ccf34a81-4594-4b58-9ffb-8e3f0824a700/public',
    },
    {
      view: <Step02 goToNextStep={goToNextStep} addTextToUsersSelections={addTextToUsersSelections} />,
      backgroundUrl: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/daf79d66-dc2b-41c1-ee40-52c7e49f5400/public',
    },
    {
      view: <Step03 goToNextStep={goToNextStep} addTextToUsersSelections={addTextToUsersSelections} />,
      backgroundUrl: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/daf79d66-dc2b-41c1-ee40-52c7e49f5400/public',
    },
    {
      view: <Step04 goToNextStep={goToNextStep} addTextToUsersSelections={addTextToUsersSelections} />,
      backgroundUrl: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/daf79d66-dc2b-41c1-ee40-52c7e49f5400/public',
    },
    /*
    {
      view: <Step05 goToNextStep={goToNextStep} addTextToUsersSelections={addTextToUsersSelections} />,
      backgroundUrl: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/daf79d66-dc2b-41c1-ee40-52c7e49f5400/public',
    },
    {
      view: (
        <StepDetailsForm
          goToNextStep={goToNextStep}
          //
          address={address}
          userProvider={userProvider}
          accountView={accountView}
          //
          setUsersTwitterHandle={setUsersTwitterHandle}
          //
          walletIsConnected={walletIsConnected}
          walletOwnershipIsVerified={walletOwnershipIsVerified}
          setWalletOwnershipIsVerified={setWalletOwnershipIsVerified}
        />
      ),
      backgroundUrl: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/daf79d66-dc2b-41c1-ee40-52c7e49f5400/public',
    },
    */
    {
      view: (
        <StepSuccess
          usersSelections={usersSelections}
          //
          twitterHandle={usersTwitterHandle}
          userWalletDetails={userWalletDetails}
          address={address || userWalletDetails.address}
          //
          tx={tx}
          userProvider={userProvider}
          localProvider={localProvider}
          externalContracts={externalContracts}
          writeContracts={writeContracts}
          gasPrice={gasPrice}
        />
      ),
      backgroundUrl: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/ccf34a81-4594-4b58-9ffb-8e3f0824a700/public',
    },
  ];

  const activeStepView = stepsList[currentStepIndex].view;

  return (
    <>
      <Background image={stepsList[currentStepIndex].backgroundUrl} />

      <div
        id="BlackOverlay"
        style={{
          position: 'fixed',
          zIndex: 0,
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(0,0,0, 0.55)',
        }}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          minHeight: '100vh',
          fontFamily: 'RalewayHeavy',
          zIndex: 2,
        }}
      >
        {activeStepView}
        {currentStepIndex !== 0 && (
          <div style={{ textAlign: 'center', marginTop: '10%', padding: '15px', fontFamily: 'Arial' }}>
            <PoweredByMemberrTag />
          </div>
        )}
      </div>
    </>
  );
}
