import React from 'react';
import { useCss } from 'react-use';

export default function Title({ children, style, styleMobile }) {
  const titleClassName = useCss({
    textAlign: 'center',
    color: '#fff',
    fontSize: '60px',
    lineHeight: '68px',
    ...style,
    '@media only screen and (max-width: 900px)': {
      fontSize: '36px',
      lineHeight: '40px',
      ...styleMobile,
    },
  });

  return <h1 className={titleClassName}>{children}</h1>;
}
