import React from 'react';

export default function OptionCircle({ onClick, image, color, text }) {
  return (
    <button
      onClick={() => onClick()}
      className="transform transition duration-200 hover:scale-105"
      style={{
        width: '200px',
        height: '200px',
        borderRadius: '100px',
        background: color,
        border: `2px solid ${color}`,
        overflow: 'hidden',
      }}
    >
      <div
        className="bg-black bg-opacity-60"
        style={{
          position: 'relative',
          top: '75px',
          padding: '10px',
          textAlign: 'center',
          zIndex: 999,
          color: '#fff',
          fontSize: '20px',
        }}
      >
        {text}
      </div>
      <div
        style={{
          position: 'relative',
          top: '-26%',
          width: '200px',
          height: '200px',
          backgroundColor: '#fff',
          zIndex: 9,
        }}
      >
        <img className="h-[200px] w-[200px]" src={image} key={text} alt={text} />
      </div>
    </button>
  );
}
