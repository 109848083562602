import React from 'react';

import { Logo, Title } from '../../sharedComponents/';
import { Header, Content, ContentFooter, Footer } from '../../LayoutComponents/';
import OptionCard from './OptionCard';

const OPTIONS = [
  {
    image: 'https://imgur.com/cWIsIwx.png',
    color: '#FB86A0',
    text: 'Flamingo',
  },
  {
    image: 'https://imgur.com/44J8hhO.png',
    color: '#DFDF59',
    text: 'Banana Boat',
  },
  {
    image: 'https://imgur.com/eBo9HCd.png',
    color: '#95D1FE',
    text: `I don't need a sidekick`,
    fontStyle: {
      fontSize: '1.1rem',
      lineHeight: '1.3rem',
    },
  },
];

export default function Step04({ addTextToUsersSelections, goToNextStep }) {
  const onOptionSelect = selectedText => {
    addTextToUsersSelections(selectedText);
    goToNextStep();
  };

  return (
    <>
      <Header className="mb-20">
        <div
          className="bg-black bg-opacity-60"
          style={{
            margin: 'auto',
            width: '100px',
            border: '2px solid rgba(0,0,0, 0.6)',
            borderTop: 'none',
            padding: '10px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            borderRadius: '0 0 1rem 1rem',
          }}
        >
          <Logo />
        </div>
        <div className="my-10">
          <Title>Which one is your ship's sidekick?</Title>
        </div>
      </Header>

      <Content>
        <div
          id="centeredCardsContainer"
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'flex-center',
            gap: '10px',
          }}
        >
          {OPTIONS.map(({ image, color, text, fontStyle }) => (
            <OptionCard
              image={image}
              color={color}
              text={text}
              fontStyle={fontStyle}
              onClick={() => onOptionSelect(text)}
            />
          ))}
        </div>
      </Content>

      <ContentFooter></ContentFooter>

      <Footer className="flex" style={{ justifyContent: 'center' }}>
        {/*
        <div className="flex" style={{ maxWidth: '1050px', justifyContent: 'center' }}>
          <img
            src="/images/BAYC_Ape_Grills.png"
            alt="BAYC_Ape_Grills"
            style={{
              alignSelf: 'flex-end',
              width: '40%',
              height: 'auto',
              marginLeft: '5%',
            }}
          />
          <SpeechBubble
            bubblePointerLeft={true}
            style={{
              alignSelf: 'flex-start',
              marginLeft: '15%',
              marginRight: '5%',
              marginBottom: '5%',
              marginTop: '5%',
            }}
          >
            <div className="p-5 text-left text-white text-xl md:text-2xl">
              Want to show-off your yachts? Want to co-build the #1 harbour?
              <br />
              Want to make use of your commercial rights? You have come to the right place!
            </div>
          </SpeechBubble>
        </div>
          */}
      </Footer>
    </>
  );
}
