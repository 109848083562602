import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useWindowSize, useCss } from 'react-use';
import Confetti from 'react-confetti';
// https://www.npmjs.com/package/searchico
import searchico from 'searchico';

import { Logo, Title, SpeechBubble } from '../../sharedComponents/';
import { Header, Content, ContentFooter, Footer } from '../../LayoutComponents/';

import ActionPanel from './ActionPanel';
import AdminSection from './AdminSection';
import RampWidget from './RampWidget';

import METADATA_JSON from '../../metadata.json';

const metadataCollection = searchico(METADATA_JSON);

// const FETCH_FLIPSIDE_MUTATION = loader('../../../../services/hasura/mutations/postToFlipside.graphql');

export default function StepSuccess({
  usersSelections,
  //
  twitterHandle,
  userWalletDetails,
  address,
  //
  tx,
  userProvider,
  localProvider,
  externalContracts,
  writeContracts,
  gasPrice,
}) {
  console.log({ usersSelections });
  console.log({ METADATA_JSON });

  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const results01 = metadataCollection.find(usersSelections[0]);

  const db2 = searchico(results01);
  const results02 = db2.find(usersSelections[1]);

  const db3 = searchico(results02);
  const results03 = db3.find(usersSelections[2]);

  const db4 = searchico(results03);
  let finalResult = db4.find(usersSelections[3]);

  console.log({ results01 });
  console.log({ results02 });
  console.log({ results03 });

  if (results02.length === 0) {
    finalResult = results01;
  } else if (results03.length === 0) {
    finalResult = results02;
  } else if (finalResult.length === 0) {
    finalResult = results03;
  }

  console.log({ finalResult });

  const nftMetaData = finalResult[0];
  console.log({ nftMetaData });

  const nftImageUrl = `https://bafybeiaqwimfg523epmvuldp57cra3lg5qrqok2kfwpzqqrqglrkbxjwfm.ipfs.dweb.link/${nftMetaData.id}.png`;
  console.log({ nftImageUrl });

  const nftOpenSeaUrl = `https://opensea.io/assets/ethereum/0x24d0cbd0d5d7b50212251c5dc7cb810e7af71f6a/${nftMetaData.id}`;
  console.log({ nftOpenSeaUrl });

  /*
  const [data, setData] = useState([]);

  const [executeFlipsideQuery, { data: flipsideMutationResult, loading, error: flipsideMutationeError }] =
    useMutation(FETCH_FLIPSIDE_MUTATION);

  useEffect(() => {
    // TODO: pass user address
    // user_address = '${address}'
    const query = {
      sql: `
        select
          *
        from
          ethereum.core.ez_current_balances
        where
          user_address = '0x34aa3f359a9d614239015126635ce7732c18fdf3'
      `,
      ttlMinutes: '5',
    };

    async function fetchData() {
      console.log('await executeFlipsideQuery() ...');
      const flipsideQueryResult = await executeFlipsideQuery({
        variables: { ...query },
      });

      console.log({ flipsideQueryResult });

      const {
        data: {
          postToFlipside: { rows, records },
        },
      } = flipsideQueryResult;

      console.log({ records });

      if (records.length) setData(records);
    }

    fetchData();
  }, [executeFlipsideQuery]);
  */

  const blackBackdropClassName = useCss({
    position: 'fixed',
    top: '50%',
    left: '50%',
    height: '95%',
    width: '70%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0,0,0, 0.65)',
    backdropFilter: 'blur(10px)',
    zIndex: 0,
    borderRadius: '10px',

    '@media only screen and (max-width: 900px)': {
      width: '95%',
    },
  });

  return (
    <>
      <Confetti width={windowWidth} height={windowHeight} />

      <div id="BlackBackdrop" className={blackBackdropClassName} />

      <Header className="mb-20">
        <div
          className="bg-black bg-opacity-60"
          style={{
            margin: 'auto',
            width: '100px',
            border: '2px solid rgba(0,0,0, 0.6)',
            borderTop: 'none',
            padding: '10px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            borderRadius: '0 0 1rem 1rem',
          }}
        >
          <Logo />
        </div>
        <div className="mt-10 py-5 text-center">
          <h1 className="mb-5 text-center text-white" style={{ fontSize: '30px' }}>
            ✨ Congrats {/*twitterHandle*/} ✨
          </h1>
          <Title styleMobile={{ padding: '0 40px' }}>
            Mighty, strong and <br />
            ready to conquer the ocean!
            <br />
            Are you ready to set sails?
          </Title>
        </div>
      </Header>

      <Content>
        <div className="px-5" style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ minWidth: '40%', maxWidth: '460px', justifyContent: 'center' }}>
            <a href={nftOpenSeaUrl} target="_blank" rel="noreferrer">
              <img className="rounded-3xl" src={nftImageUrl} alt="Mint Success" />
            </a>

            <div className="my-10">
              <ActionPanel nftMetaData={nftMetaData} nftOpenSeaUrl={nftOpenSeaUrl} />
            </div>

            {/*<AdminSection data={data} />*/}

            {/*
            <div className="flex" style={{ marginTop: '5%' }}>
              <img
                src="/images/BAYC_Ape_Gold.png"
                alt="BAYC_Ape_Gold"
                style={{
                  alignSelf: 'flex-end',
                  height: 'auto',
                  width: '30%',
                  marginLeft: '5%',
                }}
              />
              <SpeechBubble
                bubblePointerLeft={true}
                style={{
                  alignSelf: 'flex-start',
                  marginLeft: '15%',
                  marginRight: '5%',
                  marginBottom: '5%',
                }}
              >
                <div className="p-5 text-left text-white text-2xl">
                  <p>A very good choice Capetain!</p>
                  <br />
                  <p>Buy it now for 0.08 ETH and enter the community!</p>
                </div>
              </SpeechBubble>
            </div>
          */}
          </div>
        </div>
      </Content>
    </>
  );
}
