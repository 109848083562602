import React from 'react';
import { notification } from 'antd';
import DiscordIcon from './DiscordIcon.svg';

export default function ActionPanel({ nftMetaData, nftOpenSeaUrl }) {
  return (
    <div className="text-white">
      <a href={nftOpenSeaUrl} target="_blank" rel="noreferrer">
        <button className="w-full px-12 py-4 text-white text-lg font-bold rounded-lg bg-blue-600 disabled:bg-blue-300 hover:bg-blue-700">
          Make it yours 🚀
        </button>
      </a>

      {/*<RampWidget show={true} userAddress={address} onEvent={rampWidgetOnEvent} amount={42} />*/}

      <div style={{ width: '100%', textAlign: 'center' }}>
        <a href="https://discord.com/invite/73vMqt7k7H" target="_blank" rel="noreferrer">
          <button
            style={{ border: '1px solid', borderColor: 'rgb(265, 256, 256, 0.56)', borderRadius: '10px' }}
            className="w-full mt-5 px-12 py-4 cursor-pointer tex-center text-white text-lg font-bold bg-black transform transition duration-200 hover:scale-105"
          >
            <img style={{ float: 'left', marginTop: '5px', marginRight: '15px' }} src={DiscordIcon} alt="Discord" />
            Join your fellow C’apetains in our discord
          </button>
        </a>
      </div>
    </div>
  );
}
